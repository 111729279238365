.tiro-devanagari-sanskrit-regular {
    font-family: "Tiro Devanagari Sanskrit", serif;
    font-weight: 400;
    font-style: normal;
}

.tiro-devanagari-sanskrit-regular-italic {
    font-family: "Tiro Devanagari Sanskrit", serif;
    font-weight: 400;
    font-style: italic;
}