.grid-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.item-date {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.item-title {
  font-size: 2rem;
  /* Increased font size for title */
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  text-align: center;
}

.item-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.item-image {
  max-width: 100%;
  height: auto; /* Allow image to adjust its height */
  max-height: 600px; /* Limit maximum height for larger images */
  /* Set a fixed height for images */
  object-fit: contain; /* Maintain aspect ratio and contain within container */
  margin-bottom: 10px;
  cursor: pointer; /* Add pointer cursor for indicating clickable */
  transition: transform 0.3s ease; /* Add smooth transition for click effect */
}

.item-image.enlarged {
  transform: scale(1.5); /* Enlarge image when clicked */
  z-index: 2; /* Bring image to top */
}

.item-description {
  font-size: 1.6rem;
  line-height: 2;
  font-family: "Inter", sans-serif;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  /* Adjusts to fit two columns for desktop view */
  gap: 20px;
  padding: 60px;
  /* Add padding to grid container */
}

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    /* One column for tablet and mobile view */
  }
}

/* Media query for tablet screens */
@media only screen and (max-width: 768px) {
  .item-description {
    font-size: 1.4rem;
    /* Decrease font size for tablets */
  }
}

/* Media query for mobile screens */
@media only screen and (max-width: 480px) {
  .item-description {
    font-size: 1.2rem;
    /* Further decrease font size for mobile */
  }
}

/* Media query for tablet screens */
@media only screen and (max-width: 768px) {
  .item-image {
    max-height: 400px;
    /* Decrease height for tablets */
  }
}

/* Media query for mobile screens */
@media only screen and (max-width: 480px) {
  .item-image {
    max-height: 300px;
    /* Further decrease height for mobile */
  }
}

/* Media query for tablet screens */
@media only screen and (max-width: 768px) {
  .grid-container {
    padding: 30px;
    /* Decrease padding for tablet */
  }
}

/* Media query for mobile screens */
@media only screen and (max-width: 480px) {
  .grid-container {
    padding: 20px;
    /* Further decrease padding for mobile */
  }
}
