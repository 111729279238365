/* General styles for the navbar container */
.navbar-container {
    position: absolute;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    overflow: hidden;
  }

  /* Positioning for different screen sizes */
  @media only screen and (min-width: 768px) {
    .navbar-container {
      top: 68vh; /* For tablets */
    }
  }

  @media only screen and (min-width: 992px) {
    .navbar-container {
      top: 97vh; /* For desktops */
    }
  }

  @media only screen and (max-width: 767px) {
    .navbar-container {
      top: 0%; /* For mobile devices */
    }
  }

  /* Custom menu styles */
  .custom-menu {
    background-color: rgba(255, 255, 255, 0.2); /* Translucent whitish background */
    margin-left: 15%;
    margin-right: 15%;
  }

  /* Adjust margins for smaller screens */
  @media (max-width: 1496px) {
    .custom-menu {
      margin-left: 10%;
      margin-right: 10%;
    }
  }

  /* Menu item styles */
  .custom-menu .item {
    color: white !important; /* White text color for menu items */
    font-family: "Inter", sans-serif;
    font-weight: bold;
  }

  /* Font size adjustments for different screen sizes */
  @media only screen and (min-width: 768px) {
    .custom-menu .item {
      font-size: 1rem; /* For tablets */
    }
  }

  @media only screen and (min-width: 992px) {
    .custom-menu .item {
      font-size: 1.3rem; /* For desktops */
    }
  }

  @media only screen and (max-width: 767px) {
    .custom-menu .item {
      font-size: 1rem; /* For mobile devices */
    }
  }

  /* Active menu item styles */
  .custom-menu .item.active {
    color: black !important; /* Black text color for active menu items */
    background-color: white !important; /* White background color for active menu items */
    font-weight: bolder;
    border-radius: 5px;
  }


  @media only screen and (min-width: 992px) {
      .custom-menu .item.active {
        font-size: 1.4rem; /* For desktops */
      }
    }
