.greetings-container {
  position: absolute;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  overflow: hidden;
  top: 50%;
}

@media only screen and (min-width: 768px) {
  .greetings-container {
    top: 35vh;
    /* For tablets */
  }
}

@media only screen and (min-width: 992px) {
  .greetings-container {
    top: 60vh;
    /* For desktops */
  }
}

@media only screen and (max-width: 767px) {
  .greetings-container {
    top: 25vh;
    /* For mobile devices */
  }
}

.welcome-text {
  font-family: 'Inter';
  font-size: 6.0rem;
  color: white;
  font-weight: bold;
  margin-bottom: 2%;
  margin-left: 15%;
  margin-right: 15%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1496px) {
  .welcome-text {
    font-size: 5.6rem;
    margin-bottom: 1%;
  }
}

@media only screen and (max-width: 767px) {
  .welcome-text {
    font-size: 5rem;
  }
}

.tag-text {
  font-size: 2.0rem;
  margin-bottom: 2%;
}

@media (max-width: 1496px) {
  .tag-text {
    font-size: 1.8rem;
    margin-bottom: 1%;
  }
}

.desc-text {
  font-size: 2.0rem;
  margin-bottom: 4%;
}

@media (max-width: 1496px) {
  .desc-text {
    font-size: 1.8rem;
    margin-bottom: 2%;
  }
}

.border-container {
  border: 4px solid white;
  padding: 20px;
  font-size: 4rem;
}

@media (max-width: 1496px) {
  .border-container {
    border: 2px solid white;
    padding: 16px;
    font-size: 3rem;
  }
}
